<template>
  <div class="header-full wf-section">
    <div class="container-header-full desktop w-container">
      <div class="w-layout-grid grid-header-full">
        <div id="w-node-a04a9c5f-cc55-1ce0-c4f0-b169bce84971-bce8496e"><a href="https://pautions.fr" target="_blank"><img :src="require('@/assets/images/logo_pautions.svg')" loading="lazy" width="120" alt="" class="logo-pautions-full-header" /></a></div>
        <div id="w-node-a04a9c5f-cc55-1ce0-c4f0-b169bce84973-bce8496e" class="div-block-5">
          <div data-animation="over-right" data-collapse="medium" data-duration="400" data-easing="ease-in-out" data-easing2="ease-in-out" role="banner" class="w-nav">
            <div class="w-container">
              <a href="#" class="brand w-nav-brand"></a>
              <nav role="navigation" class="nav-menu w-nav-menu">
                <router-link tag="a" class="onglet-menu w-nav-link" class-active="w--current" to="/apporteur/liste" exact>Commission</router-link>
                <router-link tag="a" class="onglet-menu w-nav-link" class-active="w--current" to="/apporteur/partenaire" exact>Partenaire</router-link>
              </nav>
              <div class="menu-button w-nav-button"><div class="w-icon-nav-menu"></div></div>
            </div>
          </div>
        </div>
        <div id="w-node-c6813c6c-d8dd-ff8f-1107-c296679d331b-bce8496e" class="div-zone-connexion-header">
          <div class="zone-nom-parcours">
            <div class="cercle-id-parcours"><p class="id-nom-parcours">{{initial}}</p></div>
            <p class="nom-id-parcours">{{$store.api.user.prenom}} {{$store.api.user.nom}}<br /></p>
          </div>
          <a href="javascript:void(0)" @click="$store.api.destroyToken()" class="bouton-deconnexion w-inline-block">
            <img :src="require('@/assets/images/ic_deconnexion.svg')" loading="lazy" width="18" alt="" class="ic-deconnexion" />
            <p class="txt-deconnexion">se déconnecter</p>
          </a>
        </div>
      </div>
    </div>
    <div class="container-header-full tablette-mobile w-container">
      <div class="w-layout-grid grid-header-full mobile">
        <div id="w-node-_1b2bcd50-efba-4ba2-fb02-9408c183bcd5-bce8496e" class="div-block-5">
          <div data-animation="over-left" data-collapse="medium" data-duration="400" data-easing="ease-in-out" data-easing2="ease-in-out" role="banner" class="navbar-2 w-nav">
            <div class="container-6 w-container">
              <a href="#" class="brand w-nav-brand"></a>
              <nav role="navigation" class="nav-menu w-nav-menu">
                <img :src="require('@/assets/images/logo_pautions_gris.svg')" loading="lazy" width="100" alt="" class="logo-menu-mobile-deroule" />
                <a href="/#/apporteur/liste" class="onglet-menu mobile w-nav-link">Commission</a>
                <a href="/#/apporteur/partenaire" class="onglet-menu mobile w-nav-link">Partenaire</a>
              </nav>
              <div class="menu-button w-nav-button"><div class="w-icon-nav-menu"></div></div>
            </div>
          </div>
        </div>
        <div id="w-node-_1b2bcd50-efba-4ba2-fb02-9408c183bcd3-bce8496e" class="div-block-6">
          <a href="https://pautions.fr" target="_blank">
            <img :src="require('@/assets/images/logo_pautions.svg')" loading="lazy" width="120" alt="" class="logo-pautions-full-header" />
          </a>
        </div>
        <div id="w-node-_76ae6703-cb50-e396-0d8b-f156bcdee77d-bce8496e" class="div-zone-connexion-header-full">
          <div class="zone-nom-parcours header-full">
            <div class="cercle-id-parcours header-full"><p class="id-nom-parcours">{{initial}}</p></div>
            <p class="nom-id-parcours-header-full">{{$store.api.user.prenom}} {{$store.api.user.nom}}<br /></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "",
  data: () => ({

  }),
  computed:{
    initial(){
      var tmp = ""
      if(this.$store.api.user.prenom != null){
        tmp += this.$store.api.user.prenom.toUpperCase()[0]
      }
      if(this.$store.api.user.nom != null){
        tmp += this.$store.api.user.nom.toUpperCase()[0];
      }
      return tmp;
    }
  },
  mounted() {
    this.$store.api.initMenuMobile();
  }
}
</script>
<style lang="scss" scoped>
</style>
